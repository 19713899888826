import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  configuration: {
    fees_notification_days: false,
    value: 0,
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FEES_NOTIFICATION_CONFIGURATIONS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.FEES_NOTIFICATION_CONFIGURATIONS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.FEES_NOTIFICATION_CONFIGURATIONS_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.FEES_NOTIFICATION_CONFIGURATIONS_LOAD_REJECTED:
    case actions.FEES_NOTIFICATION_CONFIGURATIONS_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
