import React from 'react'
import { useLocation } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import Logo from '@/assets/images/Galm-Logo.png'
import { Space } from '@/global/atoms'
import { getUser } from '@/redux/authUser/actionCreators'
import clsx from 'clsx'
import Tooltip from '@/components/Tooltip'

import {
  constantsLoad,
  nationalitiesLoad,
  relationshipsLoad,
  statesLoad,
} from '@/redux/staticApis/actionCreators'
import {
  FaTachometerAlt,
  FaUserGraduate,
  FaUserTie,
  FaCalendar,
  FaHandHoldingUsd,
  FaChartLine,
  FaCog,
} from 'react-icons/fa'
import { MdFingerprint } from 'react-icons/md'
import i18n from '@/i18n/i18n'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'

type NavLI = {
  data: {
    label: string
    event: string
    link: string
    icon: JSX.Element
  }
}

const NavLI = ({ data }: NavLI) => {
  const location = useLocation()
  const isActive = location?.pathname?.split('/')[1] === data.link.split('/')[1]

  return (
    <>
      <Link
        data-tooltip-id={data.label}
        className={clsx(
          'w-12 h-12 flex items-center justify-center rounded-lg hover:bg-slate-500 cursor-pointer',
          isActive && ' bg-slate-600',
        )}
        to={data.link}
      >
        {data.icon}
      </Link>
      <Tooltip
        id={data.label}
        place="right"
        className="z-50"
      >
        <p className="text-md">{i18n.t(data.label)}</p>
      </Tooltip>
    </>
  )
}

const Navigation = () => {
  const dispatch = useAppDispatch() as any
  const {
    user: { permission_details },
  } = useAppSelector(({ auth }) => auth)

  React.useEffect(() => {
    dispatch(getUser()).then(() => {
      dispatch(constantsLoad())
      dispatch(nationalitiesLoad())
      dispatch(relationshipsLoad())
      dispatch(statesLoad())
    })
  }, [])

  return (
    <div className="sidebar">
      <div className="w-20 flex flex-col gap-3 justify-center items-center sticky top-0 z-20">
        <div className="p-3">
          <NavLink to="/">
            <img
              src={Logo}
              alt="Galm"
            />
          </NavLink>
        </div>
        <Space size={20} />
        <NavLI
          data={{
            label: 'home',
            event: 'Home Navigation Link',
            link: '/',
            icon: <FaTachometerAlt className="text-2xl text-blue-100" />,
          }}
        />
        {permission_details.Students.view && (
          <NavLI
            data={{
              label: 'students',
              event: 'Students Navigation Link',
              link: '/students',
              icon: <FaUserGraduate className="text-2xl text-blue-100" />,
            }}
          />
        )}
        {permission_details.Teachers.view && (
          <NavLI
            data={{
              label: 'teachers',
              event: 'Teachers Navigation Link',
              link: '/teachers',
              icon: <FaUserTie className="text-2xl text-blue-100" />,
            }}
          />
        )}
        <NavLI
          data={{
            label: 'time_table',
            event: 'Time Table Navigation Link',
            link: '/time_table',
            icon: <FaCalendar className="text-2xl text-blue-100" />,
          }}
        />
        {permission_details.Attendance?.view && (
          <NavLI
            data={{
              label: 'attendance',
              event: 'Attendance Navigation Link',
              link: '/attendance',
              icon: <MdFingerprint className="text-2xl text-blue-100" />,
            }}
          />
        )}
        {permission_details.Fees.view && (
          <NavLI
            data={{
              label: 'study_fees',
              event: 'Study Fees Navigation Link',
              link: '/study_fees',
              icon: <FaHandHoldingUsd className="text-2xl text-blue-100" />,
            }}
          />
        )}
        {permission_details.Reports.view && (
          <NavLI
            data={{
              label: 'reports',
              event: 'Reports Navigation Link',
              link: '/reports',
              icon: <FaChartLine className="text-2xl text-blue-100" />,
            }}
          />
        )}
        {permission_details.Settings.view && (
          <NavLI
            data={{
              label: 'settings',
              event: 'Settings Navigation Link',
              link: '/setting/school_information',
              icon: <FaCog className="text-2xl text-blue-100" />,
            }}
          />
        )}
      </div>
    </div>
  )
}

export default Navigation
