import clsx from 'clsx'
import i18n from '@/i18n/i18n'
import { CSSProperties, ReactElement } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

type ButtonProps = {
  label?: string
  btnStyle?: 'primary' | 'default' | 'danger' | 'success'
  size?: 'small' | 'large'
  className?: string
  icon?: string
  svg?: string | ReactElement
  style?: CSSProperties
  fetching?: boolean
  disabled?: boolean
  full?: boolean
  type?: 'submit' | 'button' | 'reset' | undefined
  startIcon?: ReactElement
  endIcon?: ReactElement
  onClick?: () => void
}

const Button = ({
  label,
  btnStyle,
  className,
  fetching,
  onClick,
  icon,
  svg,
  style,
  disabled,
  type,
  startIcon,
  endIcon,
  full,
  size,
}: ButtonProps): JSX.Element => (
  <button
    type={type}
    style={style}
    className={clsx(
      'flex items-center gap-1 py-2 px-3 text-sm font-medium focus:ring-1 focus:ring-primary-700 focus:ring-offset-2 rounded-md whitespace-pre',
      btnStyle === 'primary' && 'bg-primary-600 text-white',
      btnStyle === 'default' &&
        ' bg-white text-slate-700 border border-slate-500 focus:ring-slate-400 disabled:!bg-gray-200',
      btnStyle === 'danger' && 'bg-red-500 text-white focus:ring-red-400',
      btnStyle === 'success' && 'bg-green-500 text-white focus:ring-red-400',
      // size
      size === 'small' && ' py-1 px-2 text-xs',
      // !link ? `btn-${}` : 'btn-link',
      disabled && 'bg-gray-300 cursor-not-allowed',
      full && 'w-full justify-center',
      className,
    )}
    disabled={fetching || disabled}
    onClick={onClick}
  >
    {startIcon && startIcon}
    {label && i18n.t(label)}
    {!fetching && !icon && svg}
    {endIcon && endIcon}
    {fetching && <AiOutlineLoading3Quarters className="animate-spin" />}
  </button>
)

Button.defaultProps = {
  type: 'submit',
  btnStyle: 'primary',
  // size: 'medium',
}

export default Button
