import { handleResponseErr } from '@/utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  states: [],
  nationalities: [],
  relationships: [],
  knet_types: [],
  payment_methods: [],
  roles: [],
  gender: [],
  subscription_types: [],
  religion_types: [],
  child_pickers_types: [],
}

export default function staticApisReducer(state = initialState, action) {
  switch (action.type) {
    case actions.STATES_LOAD_FULFILLED:
    case actions.NATIONALITIES_LOAD_FULFILLED:
    case actions.RELATIONSHIPS_LOAD_FULFILLED:
    case actions.CONSTANTS_LOAD_FULFILLED:
    case actions.ROLES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.STATES_LOAD_REJECTED:
    case actions.NATIONALITIES_LOAD_REJECTED:
    case actions.RELATIONSHIPS_LOAD_REJECTED:
    case actions.CONSTANTS_LOAD_REJECTED:
    case actions.ROLES_LOAD_REJECTED:
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
