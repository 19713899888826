import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  study_period_section_subjects: [],
  study_period_section_subject: {},
}

export default function yearSectionSubjectsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case actions.YEAR_SECTION_SUBJECTS_REST:
      return {
        ...initialState,
      }

    case actions.YEAR_SECTION_SUBJECTS_LOAD_FULFILLED:
      return {
        ...state,
        study_period_section_subjects:
          action.payload.data.data.study_period_section_subjects,
        fetching: false,
      }

    case actions.YEAR_SECTION_SUBJECTS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.YEAR_SECTION_SUBJECTS_LOAD_REJECTED:
    case actions.YEAR_SECTION_SUBJECTS_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
