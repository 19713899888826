import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import { Action } from '@/types/redux'
import * as actions from './actions'

type initialStateType = {
  fetching: boolean
  guardians: Array<any>
}

export const initialState: initialStateType = {
  fetching: false,
  guardians: [],
}

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case actions.GUARDIANS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.GUARDIANS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case 'GUARDIANS_POST_FULFILLED':
      Toastr.success('Guardian added successfully')
      return {
        ...state,
        fetching: false,
      }

    case actions.GUARDIANS_LOAD_REJECTED:
    case 'GUARDIANS_POST_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
