import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'

export const initialState = {
  fetching: false,
}

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case 'STUDENT_REGISTRATIONS_POST_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case 'STUDENT_REGISTRATIONS_POST_FULFILLED':
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case 'STUDENT_REGISTRATIONS_POST_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
