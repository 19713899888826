import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'

export const initialState = {
  fetching: false,
  black_lists: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'BLACKLIST_LOAD_FULFILLED':
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case 'BLACKLIST_POST_FULFILLED':
    case 'BLACKLIST_PUT_FULFILLED':
    case 'BLACKLIST_DELETE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case 'BLACKLIST_LOAD_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
