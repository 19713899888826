import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  loading: true,
  fees_installments_configuration: [],
}

export default function feesInstallmentsConfigurationReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case actions.FEES_INSTALLMENTS_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      }
    case actions.FEES_INSTALLMENTS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
        loading: false,
      }

    case actions.FEES_INSTALLMENTS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.FEES_INSTALLMENTS_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.FEES_INSTALLMENTS_LOAD_REJECTED:
    case actions.FEES_INSTALLMENTS_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
        loading: false,
      }

    default:
      return state
  }
}
