import { handleResponseErr } from '@/utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  students: [],
  header: [],
  records: [],
}

export default function studentsReportsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.STUDENTS_REPORTS_LOAD_PENDING:
    case 'STUDENTS_QRCODE_REPORTS_LOAD_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case actions.STUDENTS_REPORTS_LOAD_FULFILLED:
    case 'STUDENTS_QRCODE_REPORTS_LOAD_FULFILLED':
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.STUDENTS_REPORTS_LOAD_REJECTED:
    case 'STUDENTS_QRCODE_REPORTS_LOAD_REJECTED':
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
