import { Button } from '@/global/layout'
import { useConfirmationModalStore } from '@/store/useConfirmationModalStore'
import Modal from './Modal'

export default function ConfirmationModal() {
  const { isOpen, closeModal, modalMessage, onConfirm } =
    useConfirmationModalStore()

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm() // Execute the callback function
    }
    closeModal() // Close the modal after confirmation
  }

  return (
    <Modal
      title="confirm"
      open={isOpen}
      onClose={() => {}}
    >
      <div className="mt-2">
        <p className="text-lg text-gray-500">{modalMessage}</p>
      </div>
      <div className="flex justify-end gap-3 mt-6">
        <Button
          label="confirm"
          type="button"
          btnStyle="danger"
          size="small"
          onClick={handleConfirm}
        />
        <Button
          label="cancel"
          type="button"
          btnStyle="default"
          size="small"
          onClick={closeModal}
        />
      </div>
    </Modal>
  )
}
