import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  students_gender: null,
  students_levels: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.STUDENTS_SUBSCRIPTION_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.STUDENTS_SUBSCRIPTION_UPDATE_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.STUDENTS_SUBSCRIPTION_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
