import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  header: [],
  records: [],
}

export default function studentsFeesReportsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case actions.REPORT_STUDY_PERIODS_FEES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }
    case actions.REPORT_STUDY_PERIODS_FEES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.REPORT_STUDY_PERIODS_FEES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
