import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import { updateOneListOfRecords } from '@/global/HelperFunctions'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  students_fees: [],
  student_fees: [],
  bulk_result: {},
  pagination: {},
  last_receipt_no: 0,
  student_details: {
    student_fees: [],
    student_discounts: [],
    installments: [],
    fees_payments: [],
    student: {},
  },
}

export default function studentsFeesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.STUDENTS_FEES_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.STUDENTS_FEES_LOAD_FULFILLED:
    case actions.STUDENT_FEES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.STUDENT_FEES_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        student_fees: [
          ...state.student_fees,
          action.payload.data.data.student_fee,
        ],
        fetching: false,
      }

    case actions.STUDENT_FEES_DETAILS_LOAD_FULFILLED:
      return {
        ...state,
        student_details: { ...action.payload.data.data },
        fetching: false,
      }

    case actions.LAST_RECEIPT_NO_GET_FULFILLED:
      return {
        ...state,
        last_receipt_no: action.payload.data.data.last_receipt_no,
        fetching: false,
      }

    case actions.STUDENT_FEES_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        student_fees: updateOneListOfRecords(
          state.student_fees,
          action.payload.data.data.student_fee,
        ),
        fetching: false,
      }

    case actions.STUDENTS_FEES_POST_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
        bulk_result: action.payload.data.data,
      }

    case actions.STUDENTS_FEES_LOAD_REJECTED:
    case actions.STUDENT_FEES_DETAILS_LOAD_REJECTED:
    case actions.STUDENT_FEES_LOAD_REJECTED:
    case actions.STUDENT_FEES_CREATE_REJECTED:
    case actions.STUDENT_FEES_UPDATE_REJECTED:
    case actions.STUDENT_FEES_DELETE_REJECTED:
    case actions.LAST_RECEIPT_NO_GET_REJECTED:
    case actions.STUDENTS_FEES_POST_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
