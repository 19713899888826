import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  sections: [],
  section: {},
}

export default function levelsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET:
      return {
        ...state,
        fetching: true,
      }

    case actions.SECTIONS_LOAD_PENDING:
    case actions.SECTION_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.SECTIONS_LOAD_FULFILLED:
    case actions.SECTION_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.SECTION_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        sections: [...state.sections, { ...action.payload.data.data }],
        fetching: false,
      }

    case actions.SECTION_UPDATE_FULFILLED:
    case actions.SECTION_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.SECTIONS_LOAD_REJECTED:
    case actions.SECTION_LOAD_REJECTED:
    case actions.SECTION_CREATE_REJECTED:
    case actions.SECTION_UPDATE_REJECTED:
    case actions.SECTION_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
