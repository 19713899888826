import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import { Action } from '@/types/redux'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  levels: [],
  level: {},
}

export default function levelsReducer(state = initialState, action: Action) {
  switch (action.type) {
    case actions.RESET:
      return {
        ...state,
        fetching: true,
      }

    case actions.LEVELS_LOAD_PENDING:
    case actions.LEVEL_LOAD_PENDING:
    case actions.LEVEL_UPDATE_PENDING:
    case actions.LEVEL_CREATE_PENDING:
    case actions.LEVEL_DELETE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.LEVELS_LOAD_FULFILLED:
    case actions.LEVEL_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.LEVEL_UPDATE_FULFILLED:
    case actions.LEVEL_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.LEVEL_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        levels: [...state.levels, action.payload.data.data.level],
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.LEVELS_LOAD_REJECTED:
    case actions.LEVEL_LOAD_REJECTED:
    case actions.LEVEL_UPDATE_REJECTED:
    case actions.LEVEL_CREATE_REJECTED:
    case actions.LEVEL_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
