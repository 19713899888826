import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  installments: [],
  paid_installment: {},
  external_payment_services: [],
  external_payment_services_keys: [],
}

export default function studentInstallmentsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case actions.STUDENT_INSTALLMENTS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }
    case actions.EXTERNAL_PAYMENT_SERVICES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data,
        external_payment_services_keys:
          action.payload.data.external_payment_services.map((f) => f.value),
        fetching: false,
      }

    case actions.STUDENT_INSTALLMENTS_UPDATE_PENDING:
    case actions.STUDENT_INSTALLMENTS_CREATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.STUDENT_INSTALLMENTS_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.STUDENT_INSTALLMENTS_UPDATE_FULFILLED:
    case actions.STUDENT_INSTALLMENTS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.STUDENT_INSTALLMENTS_CREATE_REJECTED:
    case actions.STUDENT_INSTALLMENTS_LOAD_REJECTED:
    case actions.STUDENT_INSTALLMENTS_UPDATE_REJECTED:
    case actions.STUDENT_INSTALLMENTS_DELETE_REJECTED:
    case actions.EXTERNAL_PAYMENT_SERVICES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
