import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react'
import clsx from 'clsx'
import i18n from '@/i18n/i18n'

type ModalProps = {
  open: boolean
  onClose: () => void
  children?: React.ReactNode
  title?: string
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}

const Modal = ({ open, onClose, children, title, size = 'md' }: ModalProps) => {
  return (
    <Dialog
      as="div"
      open={open}
      className="relative z-10"
      onClose={onClose}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-blue-900/50 duration-300 ease-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel
          transition
          className={clsx(
            'w-full space-y-4 bg-white p-6 shadow-xl rounded-2xl duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0',
            {
              'max-w-sm': size === 'sm',
              'max-w-md': size === 'md',
              'max-w-lg': size === 'lg',
              'max-w-xl': size === 'xl',
              'max-w-2xl': size === '2xl',
            },
          )}
        >
          <DialogTitle
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900 mb-3"
          >
            {i18n.t(title)}
          </DialogTitle>
          {children}
        </DialogPanel>
      </div>
    </Dialog>
  )
}

export default Modal
