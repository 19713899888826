import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import { Action } from '@/types/redux'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  template: {},
}

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case actions.EXPORT_TEMPLATE_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.CLEAR_EXPORT_TEMPLATE:
      return initialState

    case actions.EXPORT_TEMPLATE_LOAD_FULFILLED:
      return {
        ...state,
        template: action.payload.data.data,
        fetching: false,
      }

    case actions.EXPORT_TEMPLATE_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
