import { handleResponseErr } from '@/utils/apiHelperUtils'
import { updateOneListOfRecords } from '@/global/HelperFunctions'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  fee_types: [],
  fee_type: {},
}

export default function transportationFeesReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case actions.FEE_TYPES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.FEE_TYPES_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fee_types: updateOneListOfRecords(
          state.fee_types,
          action.payload.data.data.fee_type,
        ),
        fetching: false,
      }

    case actions.FEE_TYPES_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.FEE_TYPES_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        fee_types: [...state.fee_types, action.payload.data.data.fee_type],
        fetching: false,
      }

    case actions.FEE_TYPES_LOAD_REJECTED:
    case actions.FEE_TYPES_UPDATE_REJECTED:
    case actions.FEE_TYPES_CREATE_REJECTED:
    case actions.FEE_TYPES_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
