import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  fees_payments: [],
  student_fees_payment: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FEES_PAYMENTS_POST_PENDING:
    case actions.FEES_PAYMENTS_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.FEES_PAYMENTS_UPDATE_FULFILLED:
    case actions.FEES_PAYMENTS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.FEES_PAYMENTS_UPDATE_REJECTED:
    case actions.FEES_PAYMENTS_POST_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
