import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  time_table_configuration: {},
  lecture_times: [],
  new_form_data: {
    from: '',
    to: '',
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.LECTURE_TIMES_CREATE_NEW_FORM:
      return {
        ...state,
        lecture_times: [...state.lecture_times, state.new_form_data],
        fetching: false,
      }

    case actions.TIME_TABLE_CONFIGURATION_LOAD_FULFILLED:
    case actions.LECTURE_TIMES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.TIME_TABLE_CONFIGURATION_UPDATE_FULFILLED:
    case actions.LECTURE_TIMES_UPDATE_FULFILLED:
    case actions.LECTURE_TIMES_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.TIME_TABLE_CONFIGURATION_LOAD_REJECTED:
    case actions.TIME_TABLE_CONFIGURATION_UPDATE_REJECTED:
    case actions.LECTURE_TIMES_LOAD_REJECTED:
    case actions.LECTURE_TIMES_CREATE_REJECTED:
    case actions.LECTURE_TIMES_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
