import {
  handleResponseErr,
  setToken,
  removeToken,
} from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  loading_app: false,
  login: {},
  user: {
    active_study_period: {},
    active_year: {},
    permission_details: {
      Attendance: {},
      Fees: {},
      Reports: {},
      Settings: {},
      Students: {},
      Teachers: {},
    },
  },
}

export default function authUserReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOGIN_PENDING:
    case actions.FORGOT_PASSWORD_PENDING:
    case actions.REST_PASSWORD_PENDING:
      return {
        ...state,
        fetching: true,
        loading_app: false,
      }

    case actions.SCHOOL_VALIDATE_PENDING:
    case actions.GET_USER_PENDING:
      return {
        ...state,
        fetching: true,
        loading_app: true,
      }

    case actions.LOGOUT_FULFILLED:
      removeToken()
      return {
        ...state,
        fetching: false,
        loading_app: false,
      }

    case actions.GET_USER_FULFILLED:
    case actions.SCHOOL_VALIDATE_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
        loading_app: false,
      }

    case actions.FORGOT_PASSWORD_FULFILLED:
    case actions.REST_PASSWORD_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
        loading_app: false,
      }

    case actions.LOGIN_FULFILLED:
      // window.location.reload(true);
      setToken(action.payload.data.data.auth_token)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.CHANGE_PASSWORD_FULFILLED:
      Toastr.success(action.payload.data.message)
      removeToken()
      return {
        ...state,
        fetching: false,
      }

    case actions.GET_USER_REJECTED:
      removeToken()
      return {
        ...state,
        fetching: false,
        loading_app: false,
      }

    case actions.CHANGE_PASSWORD_REJECTED:
    case actions.REST_PASSWORD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
        loading_app: false,
      }

    case actions.SCHOOL_VALIDATE_REJECTED:
    case actions.LOGIN_REJECTED:
    case actions.FORGOT_PASSWORD_REJECTED:
      removeToken()
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
        loading_app: false,
      }

    default:
      return state
  }
}
