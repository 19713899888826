import { handleResponseErr } from '@/utils/apiHelperUtils'
import { updateOneListOfRecords } from '@/global/HelperFunctions'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  job_titles: [],
  job_title: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.JOB_TITLES_LOAD_FULFILLED:
    case actions.JOB_TITLE_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.JOB_TITLE_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        job_titles: [...state.job_titles, action.payload.data.data.job_title],
        fetching: false,
      }

    case actions.JOB_TITLE_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        job_titles: updateOneListOfRecords(
          state.job_titles,
          action.payload.data.data.job_title,
        ),
        fetching: false,
      }

    case actions.JOB_TITLES_LOAD_REJECTED:
    case actions.JOB_TITLE_LOAD_REJECTED:
    case actions.JOB_TITLE_CREATE_REJECTED:
    case actions.JOB_TITLE_UPDATE_REJECTED:
    case actions.JOB_TITLE_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
