import axios from 'axios'
import { API_ROOT } from '../env'
import i18n from '@/i18n/i18n'

import QueryString from 'qs'
import { buildUrlWithQueryString, getToken } from './apiHelperUtils'

const hostname = window.location.hostname.split('.')

export const API_URL = API_ROOT
export const API_VERSION_ONE = 'v1'
export const SCHOOL_SLUG = hostname.shift()

export const http = axios.create({
  baseURL: `${API_ROOT}/v1`,
  paramsSerializer(params) {
    return QueryString.stringify(params, {
      arrayFormat: 'brackets',
      encode: false,
    })
  },
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getToken(),
    slug: SCHOOL_SLUG,
    locale: i18n.language,
  },
})

http.interceptors.response.use(
  (response) => {
    if (response.config.method === 'get') {
      return response.data.data
    }
    return response.data
  },
  (error) => {
    return Promise.reject(error)
  },
)

export function handleAPI(
  path: string,
  params: any,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  data = null,
) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getToken(),
    slug: SCHOOL_SLUG,
    locale: i18n.language,
  }

  const url = buildUrlWithQueryString(API_URL + path, params)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return axios({
    method,
    url,
    data,
    headers,
  })
}
