import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  student_discounts: [],
  student_discount: {},
}

export default function studentDiscountsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.STUDENT_DISCOUNTS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.STUDENT_DISCOUNTS_CREATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.STUDENT_DISCOUNTS_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        student_discounts: [
          ...state.student_discounts,
          action.payload.data.data.student_discount,
        ],
        fetching: false,
      }

    case actions.STUDENT_DISCOUNTS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.STUDENT_DISCOUNTS_LOAD_REJECTED:
    case actions.STUDENT_DISCOUNTS_CREATE_REJECTED:
    case actions.STUDENT_DISCOUNTS_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
