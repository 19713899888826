import { handleResponseErr } from '@/utils/apiHelperUtils'
import { Toastr } from '@/global/toastr'
import { Action } from '@/types/redux'
import * as actions from './actions'

export type Record = {
  id: number
  username: string
  name: string
  avatar: string
  icons: string[]
  details: {
    status: string
    date: string
    icon?: string
    out_time?: string
    in_time?: string
  }
}

type State = {
  fetching: boolean
  days: string[]
  records: Record[]
  attendance_details: {
    attendance_records: Record['details'][]
  }
  pagination?: {
    current_page?: string
    total_count?: string
  }
}

export const initialState: State = {
  fetching: false,
  days: [],
  records: [],
  pagination: {},
  attendance_details: {
    attendance_records: [],
  },
}

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case actions.ATTENDANCE_RECORDS_STUDENTS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ATTENDANCE_RECORDS_STUDENTS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.ATTENDANCE_RECORDS_STUDENT_DETAILS_FULFILLED:
      return {
        ...state,
        attendance_details: action.payload.data.data,
        fetching: false,
      }

    case actions.ATTENDANCE_RECORDS_STUDENT_UPDATE_FULFILLED:
    case actions.ATTENDANCE_RECORDS_STUDENT_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ATTENDANCE_RECORDS_STUDENTS_LOAD_REJECTED:
    case actions.ATTENDANCE_RECORDS_STUDENT_DETAILS_REJECTED:
    case actions.ATTENDANCE_RECORDS_STUDENT_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        // errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
