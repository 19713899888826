import { Action } from '@/types/redux'
import { handleResponseErr } from '@/utils/apiHelperUtils'
import * as actions from './actions'

export type FeesHhistoryType = {
  employee_name: string
  time: string
  content: string
}

type State = {
  error: string
  fetching: boolean
  fees_setting_histories: FeesHhistoryType[]
}

export const initialState: State = {
  fetching: false,
  fees_setting_histories: [],
  error: '',
}

export default function citiesReducer(
  state = initialState,
  action: Action,
): State {
  switch (action.type) {
    case actions.FEES_SETTING_HISTORIES_LOAD_FULFILLED:
      return {
        ...state,
        fees_setting_histories: action.payload.data.data.fees_setting_histories,
        fetching: false,
      }

    case actions.FEES_SETTING_HISTORIES_LOAD_REJECTED:
      return {
        ...state,
        error: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
